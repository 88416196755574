import React, { useContext, useEffect, useMemo, useState } from "react";

import { GradeOpcoes, InputDropDowList2, InputText } from "../../../../components";
import { Grid, Button } from "@material-ui/core";

import Table from "../../../../components/grade/Table";
import ChartAccountsUtils, { LedgerAccountsNature, LedgerAccountsNatureDefault, LedgerAccountsType, LedgerAccountsTypeDefault } from "../utils";
import ApiChartAccounts from "../../../../api/accounting/chartAccounts";
import { LedgerAccountsValidate } from "../validates";
import { AppContext } from "../../../../App";
import TablePagination from "../../../../components/grade/TablePagination";

const initialValues = {
    number: "",
    name: "",
    account_type: LedgerAccountsTypeDefault,
    nature_type: LedgerAccountsNatureDefault,
    reference: "",
};

export default function LedgerAccountsChange({ ledgerAccounts, setLedgerAccounts }) {
    const { showMessageError } = useContext(AppContext);

    const [ledgerAccount, setLedgerAccount] = useState(initialValues);

    const [ledgerAccountsView, setLedgerAccountsView] = useState([]);
    const [ledgerAccountsViewPage, setLedgerAccountsViewPage] = useState(0);
    const [ledgerAccountsViewPerPage, setLedgerAccountsViewPerPage] = useState(10);

    const metaData = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Conta", field: "number" },
        { label: "Nome", field: "name" },
        {
            label: "Tipo",
            render: (record) => {
                return <>{ChartAccountsUtils().LedgerAccountsTypeText(record.account_type)}</>;
            },
        },
        {
            label: "Natureza",
            render: (record) => {
                return <>{ChartAccountsUtils().LedgerAccountsNatureText(record.nature_type)}</>;
            },
        },
        { label: "Referência", field: "reference" },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    const onClickOptions = async (record, type, index) => {
        switch (type) {
            case "edit":
                setLedgerAccount({ ...record });
                break;
            case "remove":
                const ledgerAccountsCustom = [...ledgerAccounts];
                ledgerAccountsCustom.splice(index, 1);

                setLedgerAccounts(ledgerAccountsCustom);
                break;
            default:
                break;
        }
    };

    const refreshLedgerAccountsView = (page, perPage, ledgerAccounts) => {
        setLedgerAccountsViewPerPage(perPage);
        setLedgerAccountsViewPage(page);

        const initial = page === 0 ? 0 : perPage * page;
        const length = page === 0 ? perPage : perPage * (page + 1);

        setLedgerAccountsView(ledgerAccounts.slice(initial, length));
    };

    useEffect(() => {
        refreshLedgerAccountsView(ledgerAccountsViewPage, ledgerAccountsViewPerPage, ledgerAccounts);
    }, [ledgerAccounts]);

    return (
        <>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                        <InputText
                            required
                            label="Conta"
                            value={ledgerAccount.number}
                            onChangeValue={(e) => setLedgerAccount({ ...ledgerAccount, number: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            required
                            label="Nome"
                            value={ledgerAccount.name}
                            onChangeValue={(e) => setLedgerAccount({ ...ledgerAccount, name: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputDropDowList2
                            value={ledgerAccount.account_type}
                            onChangeValue={(e) => setLedgerAccount({ ...ledgerAccount, account_type: e.target.value })}
                            data={LedgerAccountsType}
                            label={"Tipo"}
                        ></InputDropDowList2>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputDropDowList2
                            value={ledgerAccount.nature_type}
                            onChangeValue={(e) => setLedgerAccount({ ...ledgerAccount, nature_type: e.target.value })}
                            data={LedgerAccountsNature}
                            label={"Natureza"}
                        ></InputDropDowList2>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputText
                            label="Referência"
                            value={ledgerAccount.reference}
                            onChangeValue={(e) => setLedgerAccount({ ...ledgerAccount, reference: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                    <Grid item xs={12} sm={8}>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => setLedgerAccount(initialValues)}
                        >
                            Nova conta
                        </Button>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                                const ledgerAccountIndex = ledgerAccounts.findIndex((rec) => rec.number === ledgerAccount.number);
                                const ledgerAccountsCustom = [...ledgerAccounts];

                                // Validate
                                const validate = await ApiChartAccounts().apiDefault.validate(LedgerAccountsValidate(), ledgerAccount);
                                if (!validate.status) {
                                    showMessageError(validate.message);
                                    return;
                                }

                                if (ledgerAccountIndex === -1) {
                                    ledgerAccountsCustom.push(ledgerAccount);
                                } else {
                                    ledgerAccountsCustom[ledgerAccountIndex] = ledgerAccount;
                                }

                                setLedgerAccounts(ledgerAccountsCustom);
                                setLedgerAccount(initialValues);
                            }}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Table dataSource={ledgerAccountsView} metaData={metaData} />
                <TablePagination
                    rowCount={ledgerAccounts.length || 0}
                    rowPage={ledgerAccountsViewPerPage}
                    page={ledgerAccountsViewPage}
                    onPageChange={(event, newPage) => {
                        refreshLedgerAccountsView(newPage, ledgerAccountsViewPerPage, ledgerAccounts);
                    }}
                    onRowsPerPageChange={(event) => {
                        refreshLedgerAccountsView(0, event.target.value, ledgerAccounts);
                    }}
                />
            </Grid>
        </>
    );
}
