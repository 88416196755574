import * as yup from "yup";
import { LedgerAccountsNatureValues, LedgerAccountsTypeValues } from "../utils";

export function LedgerAccountsValidate() {
    return yup.object().shape({
        number: yup.string().required("Conta não informada."),
        name: yup.string().required("Nome não informado."),
        account_type: yup.string().nullable().oneOf(LedgerAccountsTypeValues, "Tipo de conta inválido."),
        nature_type: yup.string().nullable().oneOf(LedgerAccountsNatureValues, "Natureza da conta inválida."),
    });
}

export function ChartAccountsValidate() {
    return yup.object().shape({
        name: yup.string().required("Nome é obrigatório").min(3, "Nome deve ter mais que três caracteres"),
        ledger_accounts: yup.array().required("Contas não informados").min(1, "Nenhuma conta informado").of(LedgerAccountsValidate()),
    });
}
