export const LedgerAccountsTypeDefault = "assets";
export const LedgerAccountsNatureDefault = "debtor";

export const LedgerAccountsType = [
    {
        text: "Ativo",
        value: LedgerAccountsTypeDefault,
        nature: LedgerAccountsNatureDefault,
    },
    {
        text: "Passivo",
        value: "liabilities",
        nature: "creditor",
    },
    {
        text: "Despesas",
        value: "expenses",
        nature: LedgerAccountsNatureDefault,
    },
    {
        text: "Receitas",
        value: "revenues",
        nature: "creditor",
    },
];

export const LedgerAccountsNature = [
    {
        text: "Credora",
        value: "creditor",
    },
    {
        text: "Devedora",
        value: LedgerAccountsNatureDefault,
    },
];
export const LedgerAccountsTypeValues = LedgerAccountsType.map((rec) => rec.value);
export const LedgerAccountsNatureValues = LedgerAccountsNature.map((rec) => rec.value);

export default function ChartAccountsUtils() {
    const LedgerAccountsTypeText = (value) => {
        return LedgerAccountsType.find((rec) => rec.value === value)?.text || null;
    };

    const LedgerAccountsNatureText = (value) => {
        return LedgerAccountsNature.find((rec) => rec.value === value)?.text || null;
    };

    return {
        LedgerAccountsTypeText,
        LedgerAccountsNatureText,
    };
}
