import apiForms from "../../services/api-forms";
import * as yup from "yup";

class ApiService extends apiForms {
    operadorasCartoesSchema = yup.object().shape({
        id_estabelecimento: yup.string().required("Estabelecimento é obrigatório"),
        nome: yup.string().required("Nome é obrigatório").min(1, "Nome não pode ser em branco"),
    });

    defaultValues = {
        id_operadora_cartoes: undefined,
        id_estabelecimento: null,
        nome: "",
        tag_integrador: "",
    };

    defaultValuesAtributos = {
        venda_conta_cred: "",
        venda_conta_deb: "",
        venda_hist_cred: "",
        venda_hist_deb: "",
        venda_replica_matriz: "N",
        receb_conta_cred: "",
        receb_conta_deb_taxa: "",
        receb_conta_deb: "",
        receb_hist_cred: "",
        receb_hist_deb_taxa: "",
        receb_hist_deb: "",
        ajuste_conta_cred: "",
        ajuste_conta_deb: "",
        ajuste_hist_cred: "",
        ajuste_hist_deb: "",
        taxa: "0.00",
        id_leiaute: "0",
        receb_conta_cred_matriz: "",
        receb_conta_deb_matriz: "",
        receb_hist_matriz: "",
        receb_replica_matriz: "N",
        receb_ted: "N",
        receb_conta_cred_ted: "",
        receb_conta_deb_ted: "",
        receb_hist_ted: "",
        receb_valor_ted: "0.00",
        receb_conta_cred_ted_matriz: "",
        receb_conta_deb_ted_matriz: "",
        receb_hist_ted_matriz: "",
        receb_conta_deb_bruto: "N",
        filter_01: "",
        filter_02: "",
    };

    constructor() {
        super("operadorascartoes", ["atributos"]);
    }
}

export default new ApiService();
