import React from "react";

import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import auth from "./services/auth";

// Olds
import Login, { Logout } from "./pages/login";
import DefinirSenha from "./pages/definirSenha";
import RecuperarSenha from "./pages/recuperarSenha";
import Usuarios from "./pages/usuarios";
import AlterarSenha from "./pages/alterarSenha";
import Estabelecimentos from "./pages/estabelecimentos";
import OperadorasCartoes from "./pages/operadorasCartoes";
import ArquivosDiversos from "./pages/ferramentas/ArquivosDiversos";
import PlanosContas from "./pages/planosContas";
import LeiauteConsorcios from "./pages/contabilidade/leiautes/consorcios";

import { Main } from "./components";
import Dashboard from "./pages/dashboard";

// News
import DocumentsServiceOutbound from "./pages/fiscal/documents/services/outbound";
import Items from "./pages/basics/items";
import Members from "./pages/basics/members";
import Taxes from "./pages/fiscal/taxes";
import PaymentMethods from "./pages/financial/paymentMethods";
import Customizations from "./pages/system/customizations";
import DocumentsServiceInbound from "./pages/fiscal/documents/services/inbound";
import TaxesRules from "./pages/fiscal/taxesRules";
import DocumentsTypes from "./pages/basics/documentsTypes";
import DocumentsInbound from "./pages/fiscal/documents/goods/inbound";
import DocumentsOutbound from "./pages/fiscal/documents/goods/outbound";
import OutboundOrders from "./pages/stocks/outboundOrders";
import BalancesItemsReport from "./pages/stocks/reports/balancesItems";
import OrdersTypes from "./pages/basics/ordersTypes";
import OperationsNature from "./pages/basics/operationsNature";
import InboundOrders from "./pages/suppliers/inboundOrders";
import SuppliersGroups from "./pages/suppliers/groups";
import ChartAccounts from "./pages/accounting/chartAccounts";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            auth.IsAuthenticed() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

const useStyles = makeStyles((theme) => ({
    content: {
        heigth: "100px",
    },
}));

export default function Router() {
    const classes = useStyles();

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/definirsenha/:chave" component={DefinirSenha} />
                <Route exact path="/recuperarsenha/" component={RecuperarSenha} />
                <Main className={classes.content}>
                    <PrivateRoute exact path="/" component={Dashboard} />
                    <PrivateRoute exact path="/logout" component={Logout} />

                    <PrivateRoute exact path="/sistemas/usuarios" component={Usuarios} />
                    <PrivateRoute exact path="/sistemas/alterarsenha" component={AlterarSenha} />
                    <PrivateRoute exact path="/cadastros/contabilidade/planoscontas" component={PlanosContas} />
                    <PrivateRoute exact path="/cadastros/estabelecimentos" component={Estabelecimentos} />
                    <PrivateRoute exact path="/cadastros/vendas/operadorascartoes" component={OperadorasCartoes} />
                    <PrivateRoute exact path="/ferramentas/arquivos" component={ArquivosDiversos} />
                    <PrivateRoute exact path="/contabilidade/leiautes/consorcios" component={LeiauteConsorcios} />

                    {/* V2 */}
                    <PrivateRoute exact path="/accounting/chart-accounts" component={ChartAccounts} />
                    <PrivateRoute exact path="/basics/members" component={Members} />
                    <PrivateRoute exact path="/basics/items" component={Items} />
                    <PrivateRoute exact path="/basics/suppliers/groups" component={SuppliersGroups} />
                    <PrivateRoute exact path="/basics/documents-types" component={DocumentsTypes} />
                    <PrivateRoute exact path="/financial/payment-methods" component={PaymentMethods} />
                    <PrivateRoute exact path="/fiscal/documents/services/in" component={DocumentsServiceInbound} />
                    <PrivateRoute exact path="/fiscal/documents/services/out" component={DocumentsServiceOutbound} />
                    <PrivateRoute exact path="/fiscal/documents/in" component={DocumentsInbound} />
                    <PrivateRoute exact path="/fiscal/documents/out" component={DocumentsOutbound} />
                    <PrivateRoute exact path="/fiscal/taxes" component={Taxes} />
                    <PrivateRoute exact path="/fiscal/taxes-rules" component={TaxesRules} />
                    <PrivateRoute exact path="/suppliers/inbound-orders" component={InboundOrders} />
                    <PrivateRoute exact path="/stocks/basics/orders-types" component={OrdersTypes} />
                    <PrivateRoute exact path="/stocks/basics/operations-nature" component={OperationsNature} />
                    <PrivateRoute exact path="/stocks/outbound-orders" component={OutboundOrders} />
                    <PrivateRoute exact path="/stocks/reports/balances" component={BalancesItemsReport} />
                    <PrivateRoute exact path="/system/customizations" component={Customizations} />
                </Main>
            </Switch>
        </BrowserRouter>
    );
}
