import React, { useState, useEffect, useContext, useMemo } from "react";

import { AppContext } from "../../../App";

import { Grade, GradeOpcoes, PageControl, TabSheet, InputText } from "../../../components";
import { Button, Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { AppOptionsContext } from "../../../components/main/main";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import TablePagination from "../../../components/grade/TablePagination";
import ApiChartAccounts from "../../../api/accounting/chartAccounts";
import ChartAccountsChange from "./change";

const initialCondition = {
    name: "",
    inactivate: false,
};

export default function ChartAccounts() {
    const { setLoading, showMessageError } = useContext(AppContext);
    const { setOptions, setTitle } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);
    const [dataSource, setDataSource] = useState([]);

    const [rowCount, setRowCount] = useState(0);
    const [rowPage, setRowPage] = useState(10);
    const [page, setPage] = useState(0);

    const [condition, setCondition] = useState(initialCondition);
    const [conditionApply, setConditionApply] = useState(initialCondition);

    const [idInEdition, setIdInEdition] = useState(0);

    const metaData = useMemo(() => [
        {
            label: "Opções",
            render: (record) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "inactive", label: "Inativação" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Código", field: "id", align: "right" },
        { label: "Nome", field: "name" },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    useEffect(() => {
        setTitle("Plano de Contas");
        loadDataSource(0);

        return () => {
            setOptions(null);
        };
    }, []);

    const loadDataSource = async (newPage, newRowPage, newCondition) => {
        setLoading(true);

        // Condition
        const conditionCustom = makeCondition(newCondition || conditionApply);

        try {
            const result = await ApiChartAccounts().apiDefault.find({
                page: newPage + 1,
                pageSize: newRowPage || rowPage,
                condition: conditionCustom,
            });

            if (result.status) {
                setDataSource(result.data);
                setRowCount(result.info.total);
            } else {
                setDataSource([]);
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const makeCondition = (newCondition) => {
        const result = {};

        // Name
        if (newCondition.name) {
            result.name_like = encodeURIComponent(newCondition.name + "+");
        }

        if (!newCondition.inactivate) {
            result.inactivate_at_is_null = true;
        }

        return result;
    };

    const onClickOptions = (record, tipo) => {
        switch (tipo) {
            case "edit":
                setIdInEdition(record.id);
                break;
            case "inactive":
                break;
            default:
                break;
        }
    };

    const onFinalize = async (updated) => {
        setTabIndex(0);
        setIdInEdition(0);

        if (updated) {
            loadDataSource(page);
        }
    };

    useEffect(() => {
        if (tabIndex === 0) {
            setOptions(
                <>
                    <TablePagination
                        rowCount={rowCount}
                        rowPage={rowPage}
                        page={page}
                        onPageChange={(event, newPage) => {
                            loadDataSource(newPage);
                            setPage(newPage);
                        }}
                        onRowsPerPageChange={(event) => {
                            loadDataSource(0, event.target.value);
                            setPage(0);
                            setRowPage(event.target.value);
                        }}
                    />
                </>
            );
        }
    }, [tabIndex, rowCount, rowPage, page]);

    return (
        <>
            <PageControl tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Consulta">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <InputText
                                label="Nome ou parte do nome do plano de contas"
                                size="small"
                                autoFocus
                                fullWidth
                                variant="outlined"
                                value={condition.name}
                                onChangeValue={(e) => setCondition({ ...condition, name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Button
                                style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                                variant="contained"
                                size="small"
                                type="primary"
                                onClick={() => {
                                    loadDataSource(0, rowPage, condition);
                                    setConditionApply(condition);
                                }}
                                startIcon={<SearchIcon fontSize="inherit" />}
                            >
                                Pesquisar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grade dataSource={dataSource} metaData={metaData} disablepagination />
                    </Grid>
                </TabSheet>
                <TabSheet label={idInEdition ? `Plano de Contas ${idInEdition}` : null} icon={idInEdition ? null : <AddCircleIcon />}>
                    <Grid container spacing={1}>
                        <ChartAccountsChange
                            id={idInEdition}
                            onLoadRecord={() => setTabIndex(1)}
                            onFinalize={(updated) => onFinalize(updated)}
                            showOptions={tabIndex === 1}
                        />
                    </Grid>
                </TabSheet>
            </PageControl>
        </>
    );
}
