import React, { useCallback, useContext, useEffect, useState } from "react";

import { InputText, PageControl, TabSheet } from "../../../../components";

import { Grid, Button } from "@material-ui/core";
import { AppContext } from "../../../../App";

import { AppOptionsContext } from "../../../../components/main/main";
import { ChartAccountsValidate } from "../validates";
import ApiChartAccounts from "../../../../api/accounting/chartAccounts";
import LedgerAccountsChange from "../ledgerAccountsChange";
import { LedgerAccountsType } from "../utils";

const initialValues = {
    id: "",
    name: "",
};

export default function ChartAccountsChange({ id, onLoadRecord, onFinalize, showOptions }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);

    const [chartAccounts, setChartAccounts] = useState([]);
    const [ledgerAccounts, setLedgerAccounts] = useState([]);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);

        try {
            const result = await ApiChartAccounts().apiDefault.findId(id, { associates: ["ledger_accounts"] });

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            setChartAccounts(result.data);
            setLedgerAccounts(result.data.ledger_accounts || []);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setChartAccounts(initialValues);
        setLedgerAccounts([]);

        if (id) {
            onFinalize(false);
        }
    };

    const onSave = useCallback(
        async (chartAccounts, ledgerAccounts) => {
            const chartAccountsApply = {
                name: chartAccounts.name,
                ledger_accounts: [],
            };

            chartAccountsApply.ledger_accounts = (ledgerAccounts || []).map((rec) => {
                const ledgerAccountsType = LedgerAccountsType.find((laType) => laType.value === rec.account_type);

                return {
                    number: rec.number,
                    name: rec.name,
                    account_type: rec.account_type,
                    nature_type: rec.nature_type,
                    reference: rec.reference,
                    is_reducer: ledgerAccountsType?.nature !== rec.nature_type,
                };
            });

            // Validate
            const validate = await ApiChartAccounts().apiDefault.validate(ChartAccountsValidate(), chartAccountsApply);
            if (!validate.status) {
                showMessageError(validate.message);
                return;
            }

            setLoading(true);
            try {
                let response = {};

                if (id) {
                    response = await ApiChartAccounts().apiDefault.update(id, chartAccountsApply);
                } else {
                    response = await ApiChartAccounts().apiDefault.creates(chartAccountsApply);
                }

                if (!response.status) {
                    showMessageError(response.message);
                    return;
                }

                showMessageInfo("Registro atualizado com sucesso");

                onClearRecord();
                onFinalize(true);
            } finally {
                setLoading(false);
            }
        },
        [id, chartAccounts, ledgerAccounts]
    );

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        {id ? "Cancelar" : "Limpar tela"}
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(chartAccounts, ledgerAccounts)}
                    >
                        Gravar
                    </Button>
                </>
            );
        }
    }, [showOptions, chartAccounts, ledgerAccounts, id]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={7}>
                    <InputText
                        required
                        label="Nome"
                        value={chartAccounts.name}
                        onChangeValue={(e) => setChartAccounts({ ...chartAccounts, name: e.target.value })}
                    />
                </Grid>
            </Grid>
            <PageControl style={{ marginTop: "10px" }} tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Contas Contábeis">
                    <LedgerAccountsChange ledgerAccounts={ledgerAccounts} setLedgerAccounts={setLedgerAccounts} />
                </TabSheet>
            </PageControl>
        </>
    );
}
